import React, { Component } from "react";

//import { AppState } from '../../stores/AppState';

import { inject, observer, Provider } from "mobx-react";
import { AppStore } from "~/stores/AppStore";
import { RouteComponentProps } from "@gatsbyjs/reach-router";

import { LandingController } from "./LandingController";
import Events from "./components/Events";
import LateEntryModal from "./components/LateEntryModal";
import Footer from "../../components/footer/Footer";

interface Props {
  store?: any;
}

@inject("store")
@observer
export default class Landing extends Component<Props & RouteComponentProps> {
  store: AppStore;
  landingController: LandingController;

  constructor(props: any) {
    super(props);
    // makeObservable(this, {
    //   events: observable,
    // });

    this.store = props.store;
    this.landingController = new LandingController(this.store);
    this.store.loadEvents();
  }

  componentDidMount() {}

  render() {
    console.log("RENDER LANDING");

    return (
      <Provider controller={this.landingController}>
        <div className="mb-12">
          <div className="p-4 md:m-4 mb-6 md:rounded-md bg-gray-300 flex items-center justify-center text-xl font-bold">
            Road Running Events - Season 2025
          </div>
          {/* <Events />; */}
          <Events events={this.store.events} />
          <LateEntryModal />
        </div>
        <Footer />
      </Provider>
    );
  }
}
